<template>
  <div>
    <b-table
      :items="files"
      :fields="fields"
      responsive
    >
      <!-- Example scoped slot for select state illustrative purposes -->
      <template #cell(size)="data">
        {{ data.value | prettyBytes }}
      </template>
      <template #cell(tools)="data">
        <b-btn
          size="sm"
          variant="outline-secondary"
          @click="remove(data.index)"
        >
          <feather-icon
            size="16"
            icon="XIcon"
          />
        </b-btn>
      </template>
    </b-table>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'

export default {
  components: {},

  props: {
    files: {
      type: Array,
      default() {
        return []
      },
    },
  },

  data() {
    return {
      fields: [
        {
          key: 'name',
          label: 'NOMBRE',
        },
        {
          key: 'size',
          label: 'Tamaño',
          tdClass: 'text-center',
          thClass: 'text-center',
        },
        {
          key: 'tools',
          label: 'Herramienta',
          tdClass: 'text-center',
          thClass: 'text-center',
        },
      ],
    }
  },

  computed: {},

  methods: {
    ...mapMutations('suppliers', {
      removeFile: 'REMOVE_FILE',
    }),
    remove(index) {
      this.removeFile(index)
    },
  },
}
</script>

<style lang="scss" scoped></style>
