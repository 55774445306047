<template>
  <div v-if="!loading">
    <b-row class="flex-column">
      <b-col>
        <h2 class="font-weight-bold text-dark">
          {{ $t('remission') }}
        </h2>
      </b-col>
    </b-row>
    <b-row class="flex-column">
      <b-col
        sm="12"
        class="d-flex justify-content-end"
      >
        <b-badge
          :variant="$helpers.shippingStatusColor(purchaseOrder.shipping_status)"
          class="h3"
        >
          {{ $helpers.shippingStatusName(purchaseOrder.shipping_status) }}
        </b-badge>
      </b-col>
    </b-row>
    <b-row class="mt-2">
      <b-col
        sm="12"
        class="mt-3 text-center"
      >
        <b-img
          :src="
            require('@/assets/images/yoconstruyo/imagen-datos-bancarios.png')
          "
        />
      </b-col>
    </b-row>
    <b-row
      v-if="
        purchaseOrder.shipping_status === 'shipping_pending' ||
          purchaseOrder.shipping_status === 'shipping_rejected'
      "
      class="mt-2 justify-content-center"
    >
      <b-col
        sm="12"
        class="p-0 text-center"
      >
        <h3 class="text-dark">
          {{ $t('uploadRemission') }}:
        </h3>
      </b-col>
      <b-col
        sm="12"
        lg="4"
        class="p-0 text-center mt-1"
      >
        <dropzone :height="'150'" />
      </b-col>
    </b-row>
    <b-row
      v-else
      class="mt-2"
    >
      <b-col
        sm="12"
        class="d-flex justify-content-center mb-1"
      >
        <div class="d-flex flex-column">
          <b-button
            size="sm"
            variant="outline-primary"
          >
            <b-link
              target="_blank"
              :href="purchaseOrder.document_attachment"
            >
              <span class="h2 text-primary">
                {{ $t('invoice') }}
              </span>
              <feather-icon
                size="25"
                icon="ArrowDownIcon"
              />
            </b-link>
          </b-button>
        </div>
      </b-col>
    </b-row>
    <b-row v-if="currentUser.role_name === 'admin'">
      <b-col class="text-right">
        <b-button
          v-if="purchaseOrder.shipping_status === 'awaiting_shipping_approval'"
          v-b-modal.confirmReject
          variant="outline-danger"
          class="mb-75"
        >
          {{ $t('reject') }}
        </b-button>

        <!-- Button: Add Payment -->
      </b-col>
      <b-col class="text-left">
        <b-button
          v-if="purchaseOrder.shipping_status === 'awaiting_shipping_approval'"
          v-b-modal.confirmApprove
          variant="outline-success"
          class="mb-75"
        >
          {{ $t('approve') }}
        </b-button>
      </b-col>
      <confirmation-modal
        v-if="purchaseOrder.shipping_status === 'awaiting_shipping_approval'"
        :id="'confirmApprove'"
        :variant="'success'"
        @confirm="approveShip"
      >
        <template v-slot:content>
          {{ $t('approveShipmentConfirmation') }}
        </template>
      </confirmation-modal>

      <confirmation-modal
        v-if="purchaseOrder.shipping_status === 'awaiting_shipping_approval'"
        :id="'confirmReject'"
        :variant="'danger'"
        @confirm="rejectShip"
      >
        <template v-slot:content>
          {{ $t('rejectShipmentConfirmation') }}
        </template>
      </confirmation-modal>
    </b-row>

    <b-row
      v-if="newProviderFiles.length > 0"
      class="mt-2 justify-content-center"
    >
      <b-col>
        <files-table :files="newProviderFiles" />
      </b-col>
    </b-row>
    <b-row
      v-if="newProviderFiles.length > 0"
      class="mt-2 justify-content-center"
    >
      <b-col class="text-center">
        <b-button
          variant="primary"
          @click="upload()"
        >
          {{ $t('uploadRemission') }}
        </b-button>
      </b-col>
    </b-row>
    <b-row class="mt-2">
      <b-col
        sm="12"
        class="p-0 text-center"
      >
        <h3 class="text-primary">
          {{ $t('contactInfo') }}:
        </h3>
        <h3 class="text-dark">
          contacto@yoconstruyo.com.mx
        </h3>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Dropzone from '@/components/dropzone/Dropzone.vue'
import FilesTable from '@/components/tables/FilesTable.vue'
import ConfirmationModal from '@/components/ConfirmationModal.vue'

export default {
  components: {
    Dropzone,
    FilesTable,
    ConfirmationModal,
  },

  data() {
    return {
      attachment: null,
      loading: false,
    }
  },

  computed: {
    ...mapGetters('suppliers', ['suppliers', 'newProviderFiles']),
    ...mapGetters('purchaseOrders', ['purchaseOrders', 'purchaseOrder']),
    ...mapGetters('auth', ['currentUser']),
    ...mapState(['apiUrl']),
  },

  created() {
    this.loading = true
    this.fetchPurchaseOrder(this.$route.params.purchaseOrderId).then(() => {
      this.loading = false
    })
  },

  methods: {
    ...mapActions('purchaseOrders', [
      'fetchPurchaseOrder',
      'fetchPurchaseOrders',
      'shipPurchaseOrder',
      'approveShipment',
      'rejectShipment',
    ]),

    upload() {
      this.$getBase64(this.newProviderFiles[0]).then(data => {
        this.shipPurchaseOrder({
          purchaseOrderId: this.purchaseOrder.id,
          attachment: { data },
        }).then(response => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t('remissionSubmitted'),
              icon: 'EditIcon',
              variant: 'success',
            },
          })
          this.$router.push({
            name: 'purchaseOrder',
            params: { quoteId: response.quote.id },
          })
        })
      })
    },

    approveShip() {
      this.approveShipment({
        purchaseOrderId: this.$route.params.purchaseOrderId,
      }).then(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t('paymentApproved'),
            icon: 'CreditCardIcon',
            variant: 'success',
          },
        })
      })
    },

    rejectShip() {
      this.rejectShipment({
        purchaseOrderId: this.$route.params.purchaseOrderId,
      }).then(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t('paymentRejected'),
            icon: 'CreditCardIcon',
            variant: 'warning',
          },
        })
      })
    },
  },
}
</script>

<style></style>
